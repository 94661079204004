import $ from 'jquery';
import Component from '../models/Component';

export default class NavigationSticky extends Component {
    constructor(element, context) {
        super(element, context);

        $(() => {
            function updateActiveLink() {

                $('.navigation_link').removeClass('active');
                const hash = window.location.hash.substring(1);
                if (hash) {
                    const activeLink = $('a.' + hash);
                    if (activeLink.length) {
                        activeLink.addClass('active');
                    }
                }
            }

            updateActiveLink();
            $(window).on('hashchange', updateActiveLink);

            $(window).on('scroll', function() {
                const scrollPosition = $(window).scrollTop();
                let currentSection = '';

                $('section').each(function() {
                    const sectionTop = $(this).offset().top;
                    const sectionHeight = $(this).outerHeight();

                    if (scrollPosition >= sectionTop - sectionHeight / 2) {
                        currentSection = $(this).attr('id');
                    }
                });

                if (currentSection) {
                    history.replaceState(null, null, '#' + currentSection);
                    updateActiveLink();
                }
            });
        });
    }
}
